<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
            class="logo-image"
          />
        </b-link>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Mot de passe oublié ?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Mot de passe"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Mot de passe"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Se souvenir de moi
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Se connecter
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BImg, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import firebase from 'firebase'
import updateUserAbility from '@/libs/acl/updateUserAbility'
import { $themeConfig } from '@themeConfig'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    const toast = useToast()

    const toastFrontOfficeUser = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Net&Co met à votre disposition cet espace extranet pour vous permettre de suivre le stock des véhicules que vous nous avez confiés.',
          icon: 'AlertTriangleIcon',
        },
      }, { timeout: false })
    }

    return {
      appLogoImage,
      toastFrontOfficeUser,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isFrontOfficeUser() {
      return !this.$ability.can('manage', 'Vehicule')
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          firebase
            .auth()
            .signInWithEmailAndPassword(this.userEmail, this.password)
            .then(userCredential => {
              store.dispatch('appAuth/fetchUser', userCredential.user).then(() => {
                updateUserAbility()
                this.$router.replace({ name: 'vehicules-stock' })
                if (this.isFrontOfficeUser) {
                  this.toastFrontOfficeUser()
                }
              })
            })
            .catch(err => {
              let message
              switch (err.code) {
                case 'auth/user-not-found':
                  message = { Email: 'Email inconnu' }
                  break
                case 'auth/wrong-password':
                  message = { 'Mot de passe': 'Mot de passe incorrect' }
                  break
                default:
                  message = { Email: `Une erreur technique (${err.code}) est survenue, veuillez réessayer plus tard` }
              }
              this.$refs.loginForm.setErrors(message)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.logo-image{
  width: 80%;
  height: 80%;
}
</style>
